<template>
  <div class="score_rank_chart">
    <div class="rank_list">
      <div class="item common_box_style">
        <div class="img_box">
          <i class="iconfont icon-paiming"></i>
        </div>
        <div class="info">
          <span>总分排名</span>
          <template v-if="userData.class_bank">
            <p>第{{ userData.class_bank }}名</p>
            <span>总分: {{ userData.total_score }}分</span>
          </template>
          <template v-else>
            <p>暂无</p>
          </template>
        </div>
      </div>
      <div class="item common_box_style">
        <div class="img_box">
          <i class="iconfont icon-gouwuche"></i>
        </div>
        <div class="info">
          <span>流程分排名</span>
          <template v-if="userData.flow_bank">
            <p>第{{ userData.flow_bank }}名</p>
            <span>总分: {{ userData.flow_score }}分</span>
          </template>
          <template v-else>
            <p>暂无</p>
          </template>
        </div>
      </div>
      <div class="item common_box_style">
        <div class="img_box">
          <i class="iconfont icon-yunying"></i>
        </div>
        <div class="info">
          <span>运营分排名</span>
          <template v-if="userData.operation_bank">
            <p>第{{ userData.operation_bank }}名</p>
            <span>总分: {{ userData.operation_score }}分</span>
          </template>
          <template v-else>
            <p>暂无</p>
          </template>
        </div>
      </div>
      <div class="item common_box_style">
        <div class="img_box">
          <i class="iconfont icon-045zhuanqulirun"></i>
        </div>
        <div class="info">
          <span>利润分排名</span>
          <template v-if="userData.profit_bank">
            <p>第{{ userData.profit_bank }}名</p>
            <span>总分: {{ userData.profit_score }}分</span>
          </template>
          <template v-else>
            <p>暂无</p>
          </template>
        </div>
      </div>
      <div class="item common_box_style">
        <div class="img_box">
          <i class="iconfont icon-pingfen"></i>
        </div>
        <div class="info">
          <span>教师评分排名</span>
          <template v-if="userData.teacher_bank">
            <p>第{{ userData.teacher_bank }}名</p>
            <span>总分: {{ userData.teacher_score }}分</span>
          </template>
          <template v-else>
            <p>暂无</p>
          </template>
        </div>
      </div>
    </div>
    <div class="chart_container">
      <div class="item common_box_style">
        <div class="top">
          <div class="title">总分排行榜（Top10）</div>
          <div class="more" @click="showMore('1')">查看更多</div>
        </div>
        <div id="total_chart" v-loading="totalLoading"></div>
      </div>
      <div class="item common_box_style">
        <div class="top">
          <div class="title">流程分排行榜（Top10）</div>
          <div class="more" @click="showMore('2')">查看更多</div>
        </div>
        <div class="chart_box" v-loading="stepLoading">
          <div id="step_chart"></div>
          <ul class="step_list">
            <li v-for="(item, index) in stepInfo" :key="item.name">
              <div class="name">
                <span class="num" :style="{ 'background-color': setpColors[index] }">{{ index + 1 }}</span>
                {{ item.name }}
              </div>
              <span class="fenshu">{{ item.score }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="item common_box_style">
        <div class="top">
          <div class="title">运营分排行榜（Top10）</div>
          <div class="more" @click="showMore('3')">查看更多</div>
        </div>
        <div id="operate_chart" v-loading="operateLoading"></div>
      </div>
      <div class="item common_box_style">
        <div class="top">
          <div class="title">利润分排行榜（Top10）</div>
          <div class="more" @click="showMore('4')">查看更多</div>
        </div>
        <ul class="triangle" id="profig_box" ref="triangle">
          <li v-for="(item, index) in profitList" :ref="`tri_li${index}`" :key="index" :style="{
            'border-left-width': liw + 'px',
            'border-right-width': liw + 'px',
            width: `calc(100% - ${liw * 2 * (index + 1)}px)`,
            'border-top-color': profitColors2[index],
          }">
            <el-tooltip placement="top" effect="light">
              <div style="line-height: 20px; text-align: center" slot="content">
                {{ item.name }}({{ item.stucode }})<br />利润分:
                {{ item.score }}分
              </div>
              <span>{{ item.name }}: {{ item.score }}分</span>
            </el-tooltip>
          </li>
        </ul>
        <!-- <div id="profit_chart" v-loading="profitLoading"></div> -->
      </div>
      <div class="item common_box_style">
        <div class="top">
          <div class="title">教师评分排行榜（Top10）</div>
          <div class="more" @click="showMore('5')">查看更多</div>
        </div>
        <div class="teacher_rank" v-loading="teacherLoading">
          <ul>
            <template v-if="teacherRank.length > 0">
              <li v-for="(item, index) in teacherRank" :key="item.name">
                <div class="num_item">
                  <span class="num">{{ index + 1 }}</span>
                  <span class="name">{{ item.name }}({{ item.stucode }})</span>
                </div>
                <span>{{ item.score }}分</span>
              </li>
            </template>
            <li style="justify-content: center" v-else>暂无排名</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getScoreBank,
  getFlowBank,
  getOperationBank,
  getProfitBank,
  getTeacherBank,
} from "@/api/shop.js";
import * as echarts from "echarts";
export default {
  props: ["userinfo"],
  data() {
    return {
      userData: {},
      baseUrl: "/aliexpress/index.php?act=score_statistics&op=",
      setpColors: [
        "#3aa0ff",
        "#4eca72",
        "#f3cd36",
        "#e2f126",
        "#7685ff",
        "#f04864",
        "#36cbcb",
        "#d2859e",
        "#ddc6a1",
        "#975fe4",
      ],
      profitColors: [
        "#02a7f0",
        "#ffff80",
        "#facd91",
        "#80ffff",
        "#ec808d",
        "#00bfbf",
        "#c280ff",
        "#808000",
        "#8080ff",
        "#d7d7d7",
      ],
      profitColors2: [
        "#c1e78a",
        "#7fec7f",
        "#77e7a5",
        "#91efef",
        "#6699ff",
        "#d864ff",
        "#ff90fe",
        "#f3857c",
        "#facd91",
        "#faef91",
      ],
      stepInfo: {}, // 流程分排行榜
      teacherRank: [], // 教师评分排行榜
      totalLoading: false,
      stepLoading: false,
      operateLoading: false,
      profitLoading: false,
      teacherLoading: false,
      liw: 0,
      profitList: [],
      // 总分排行榜
      setOption_total: {
        color: ["#74dfb2"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: (params) => {
            return `${params[0].name}(${params[0].data.account})<br/><div style="text-align: left"><span>${params[0].marker}总分:</span> &nbsp;&nbsp;&nbsp;<span>${params[0].value}</span>分</div> `;
          },
          // formatter: '{a}<br />{b}'
        },
        grid: {
          left: 150,
          top: 20,
          right: 80,
          bottom: 20,
        },
        xAxis: {
          show: false,
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: [],
          axisLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
        },
        series: [
          {
            type: "bar",
            barWidth: 20, // 柱形宽度
            barCategoryGap: "30%", //柱间距离，默认为类目间距的20%
            label: {
              show: true,
              // distance: 500
              position: "right",
            },
            data: [],
          },
        ],
      },
      // 流程分
      setOption_step: {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            return `${params.data.name}(${params.data.account})<br/><div style="text-align: left"><span>${params.marker}流程分:</span>&nbsp;&nbsp;&nbsp;<span>${params.value}</span>分</div>`;
          },
        },
        series: [
          {
            name: "流程分",
            type: "pie",
            radius: ["64%", "82%"],
            center: ["50%", "40%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 2,
            },
            zlevel: 99,
            label: {
              normal: {
                show: false,
                position: "center",
                // backgroundColor: '#fff',
                // color: '#4c4a4a',
                // formatter: function () {
                // 	// return '销售额 \r\n ¥ 123,224'
                // 	return '{total|销售额}' + '\n\r' + '{active|¥ 123,224}'
                // },
                // formatter: '{total|{b}}' + '\n\r' + '{active|{c}}',
                rich: {
                  total: {
                    fontSize: 14,
                    color: "#00000073",
                  },
                  active: {
                    fontSize: 30,
                    color: "#000",
                    lineHeight: 44,
                  },
                },
              },
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "40",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [],
          },
        ],
      },
      // 运营得分排行
      setOption_operate: {
        tooltip: {
          show: true,
          trigger: "axis",
          formatter: (params) => {
            return `${params[0].name}(${params[0].data.account})<br/><div style="text-align: left"><span>${params[0].marker}运营分:</span>&nbsp;&nbsp;&nbsp;<span>${params[0].value}</span>分</div>`;
          },
        },
        xAxis: {
          name: "姓名",
          type: "category",
          data: [],
          axisLabel: {
            show: true,
            interval: 0,
            formatter: function (val) {
              var str = val.split("");
              return str.join("\n");
            },
          },
        },
        yAxis: {
          type: "value",
          name: "单位：分",
        },
        grid: {
          bottom: 120,
        },
        series: [
          {
            type: "bar",
            name: "分值",
            color: "#02a7f0",
            barWidth: "22",
            data: [],
          },
        ],
      },
      // 利润得分排行榜
      setOption_profit: {
        tooltip: {
          trigger: "item",
          // formatter: '{a} <br/>{b}'
          formatter: (params) => {
            return `${params.data.nameValue}(${params.data.account})<br/><div style="text-align: left"><span>${params.marker}利润分:</span>&nbsp;&nbsp;&nbsp;<span>${params.value}</span>分</div>`;
          },
        },
        grid: {
          top: 40,
          bottom: 40,
        },
        series: [
          {
            name: "利润得分",
            type: "funnel",
            left: "5%",
            top: 50,
            bottom: 50,
            width: "90%",
            min: 0,
            max: 20, // 最大值
            minSize: "0%",
            maxSize: "100%",
            sort: "descending", // 排队 ascending升序， descending倒序
            gap: 0, // 图形间距
            label: {
              show: true,
              position: "inside",
              color: "#000",
            },
            labelLine: {
              length: 10,
              lineStyle: {
                width: 1,
                type: "solid",
              },
            },
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
            },
            emphasis: {
              label: {
                fontSize: 16,
              },
            },
            data: [],
          },
        ],
      },
    };
  },
  created() {
    this.userData = this.userinfo;

    setTimeout(() => {
      this.getScoreDetail();
    }, 300);
    setTimeout(() => {
      this.getStepDetail();
    }, 500);
    setTimeout(() => {
      this.getOperateDetail();
    }, 700);
    setTimeout(() => {
      this.getProfitDetail();
    }, 900);
    setTimeout(() => {
      this.getTeacherRank();
    }, 1100);
  },
  mounted() {
    // 绘制漏斗排行榜
    this.liw = this.mathLine();
  },
  methods: {
    // 计算倒三角border-left的宽度
    mathLine() {
      let uw = this.$refs.triangle.clientWidth;
      let lih = 25;
      let ulh = 250 + 25;
      let w = uw / 2;
      let tanx = ulh / w;
      let angle = Math.round((Math.atan(tanx) * 180) / Math.PI);
      let angle2 = 90 - angle;
      let tanangle2 = Math.tan((angle2 * Math.PI) / 180);
      return tanangle2 * lih;
    },
    // 插入无数据图片
    appendEmptyImg(name) {
      // var img = new Image();
      // img.src = "/aliexpress/templates/default/images/empty_chart.png";
      // img.classList.add("empty_chart_img");
      let text = document.createElement("div");
      text.innerHTML = '暂无数据'
      text.style['color'] = '#b0b0b0'
      text.style['padding-top'] = '120px'
      document.getElementById(name).appendChild(text);
    },
    // 插入无数据图片
    appendEmptyImgs(name) {
      // var img = new Image();
      // img.src = "/aliexpress/templates/default/images/empty_chart.png";
      // img.classList.add("empty_chart_img");
      let text = document.createElement("div");
      text.innerHTML = '暂无数据'
      text.style['color'] = '#b0b0b0'
      text.style['padding-top'] = '100px'
      document.getElementById(name).appendChild(text);
    },
    // 得分排行榜
    getScoreDetail() {
      this.totalLoading = true;
      getScoreBank().then((res) => {
        if (res.code == 1) {
          this.totalLoading = false;
          if (res.data.echarts_x.length > 0) {
            this.setOption_total.yAxis.data = res.data.echarts_x.reverse();
            this.setOption_total.series[0].data = res.data.echarts_y;
            let y = [];
            res.data.echarts_y.forEach((item, index) => {
              var it;
              if (index < 3) {
                it = {
                  value: item,
                  account: res.data.echarts_stucode[index],
                  itemStyle: {
                    color: "#facd91",
                  },
                };
              } else {
                it = {
                  value: item,
                  account: res.data.echarts_stucode[index],
                };
              }
              y.push(it);
            });
            this.setOption_total.series[0].data = y.reverse();
            let myChart = echarts.init(document.getElementById("total_chart"));
            myChart.setOption(this.setOption_total);
          } else {
            this.appendEmptyImg("total_chart");
          }
        }
      });
    },
    // 流程分排行榜
    getStepDetail() {
      this.stepLoading = true;
      getFlowBank().then((res) => {
        if (res.code == 1) {
          this.stepLoading = false;
          this.stepInfo = res.data;
          let data = [];
          res.data.forEach((item, index) => {
            let obj = {
              value: item.score,
              name: item.name,
              account: item.stucode,
              itemStyle: {
                color: this.setpColors[index],
              },
            };
            data.push(obj);
          });
          this.setOption_step.series[0].data = data;
          let myChart = echarts.init(document.getElementById("step_chart"));
          myChart.setOption(this.setOption_step);
        }
      });
    },
    // 运营分排行榜
    getOperateDetail() {
      this.operateLoading = true;
      getOperationBank().then((res) => {
        if (res.code == 1) {
          this.operateLoading = false;
          if (res.data && res.data.echarts_x) {
            this.setOption_operate.xAxis.data = res.data.echarts_x;
            var y = [];
            res.data.echarts_y &&
              res.data.echarts_y.forEach((item, index) => {
                y.push({
                  value: item,
                  account: res.data.echarts_stucode[index],
                });
              });
            this.setOption_operate.series[0].data = y;
            let myChart = echarts.init(
              document.getElementById("operate_chart")
            );
            myChart.setOption(this.setOption_operate);
          } else {
            this.appendEmptyImg("operate_chart");
          }
        }
      });

      //   $.post(
      //     this.baseUrl + "get_operation_bank",
      //     {},
      //     (res) => {
      //       this.operateLoading = false;
      //       if (res.data && res.data.echarts_x) {
      //         this.setOption_operate.xAxis.data = res.data.echarts_x;
      //         var y = [];
      //         res.data.echarts_y &&
      //           res.data.echarts_y.forEach((item, index) => {
      //             y.push({
      //               value: item,
      //               account: res.data.echarts_stucode[index],
      //             });
      //           });
      //         this.setOption_operate.series[0].data = y;
      //         let myChart = echarts.init(
      //           document.getElementById("operate_chart")
      //         );
      //         myChart.setOption(this.setOption_operate);
      //       } else {
      //         this.appendEmptyImg("operate_chart");
      //       }
      //     },
      //     "JSON"
      //   );
    },
    // 利润分排行榜
    getProfitDetail() {
      this.profitLoading = true;
      getProfitBank().then((res) => {
        if (res.code == 1) {
          this.profitLoading = false;
          if (res.data && res.data.length > 0) {
            this.profitList = res.data || [];
          } else {
            this.appendEmptyImgs("profig_box");
          }
        }
      });
    },
    // 教师评分排行榜
    getTeacherRank() {
      this.teacherLoading = true;
      getTeacherBank().then((res) => {
        if (res.code == 1) {
          this.teacherLoading = false;
          this.teacherRank = res.data;
        }
      });
    },
    // 查看更多
    showMore(val) {
      this.$emit("show_more", val);
    },
  },
  watch: {
    userinfo(val) {
      this.userData = val;
    },
  },
};
</script>
<style lang="less" scoped>
@import "./font/iconfont.css";

.score_rank_chart {
  width: 100%;
}

.score_rank_chart .rank_list {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 80px;
}

.score_rank_chart .rank_list .item {
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 18%;
  border-radius: 4px !important;
}

.score_rank_chart .rank_list .item .img_box {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  line-height: 56px;
  text-align: center;
  background-color: #77e7a5;
  color: #fff;
  margin: 0 20px;
}

.score_rank_chart .rank_list .item:nth-of-type(2) .img_box {
  background-color: #ff9933;
}

.score_rank_chart .rank_list .item:nth-of-type(3) .img_box {
  background-color: #69f;
}

.score_rank_chart .rank_list .item:nth-of-type(4) .img_box {
  background-color: #f3857c;
}

.score_rank_chart .rank_list .item:nth-of-type(5) .img_box {
  background-color: #facd91;
}

.score_rank_chart .rank_list .item .img_box i {
  font-size: 30px;
}

.score_rank_chart .rank_list .item .info span {
  font-size: 12px;
  color: #989898;
}

.score_rank_chart .rank_list .item .info p {
  font-size: 28px;
  color: #666;
  font-weight: 500;
  margin: 8px 0;
}

.score_rank_chart .chart_container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.score_rank_chart .chart_container .item {
  width: 48%;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 30px;
}

.score_rank_chart .chart_container .item .top {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  margin-bottom: 15px;
}

.score_rank_chart .chart_container .item .top .title {
  font-size: 16px;
  color: #333;
}

.score_rank_chart .chart_container .item .top .more {
  font-size: 13px;
  color: #008fcc;
  cursor: pointer;
}

.score_rank_chart #total_chart,
.score_rank_chart #operate_chart,
.score_rank_chart #profit_chart {
  width: 100%;
  height: 350px;
  text-align: center;
}

.score_rank_chart #step_chart {
  width: 250px;
  height: 350px;
}

.score_rank_chart .chart_box {
  display: flex;
  justify-content: space-around;
}

.score_rank_chart .chart_box .step_list {
  width: 250px;
}

.score_rank_chart .chart_box .step_list li {
  display: flex;
  justify-content: space-between;
  line-height: 28px;
  vertical-align: middle;
  padding-right: 20px;
  color: #000000a5;
}

.score_rank_chart .chart_box li .name .num {
  display: inline-block;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  background-color: #3aa0ff;
  vertical-align: middle;
  line-height: 17px;
  margin-top: -2px;
  margin-right: 20px;
  font-size: 13px;
}

.score_rank_chart .teacher_rank ul {
  width: 100%;
}

.score_rank_chart .teacher_rank li {
  display: flex;
  line-height: 32px;
  vertical-align: middle;
  color: #000000a5;
  text-align: left;
}

.score_rank_chart .teacher_rank li .num_item {
  width: 400px;
}

.score_rank_chart .teacher_rank li .num {
  display: inline-block;
  color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  text-align: center;
  overflow: hidden;
  background-color: #d7d7d7;
  vertical-align: middle;
  line-height: 17px;
  margin-top: -2px;
  margin-right: 20px;
  font-size: 13px;
}

.score_rank_chart .teacher_rank li:first-of-type .num {
  background-color: #f59a23;
}

.score_rank_chart .teacher_rank li:nth-of-type(2) .num {
  background-color: #facd91;
}

.score_rank_chart .teacher_rank li:nth-of-type(3) .num {
  background-color: #aaaaaa;
}

.score_rank_chart .empty_chart_img {
  display: inline-block;
  height: 100%;
  width: auto;
}

.score_rank_chart .common_box_style {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  border-radius: 8px;
  background-color: #fff;
}

.score_rank_chart .triangle {
  width: 85%;
  margin: 40px auto 0;
  text-align: center;
}

.score_rank_chart .triangle li {
  height: 0;
  border-top: 25px solid #61a5e8;
  border-left-color: transparent;
  border-right-color: transparent;
  border-right-style: solid;
  border-left-style: solid;
  position: relative;
  margin: 0 auto;
  margin-bottom: 2px;
  transition: all 0.3s;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
}

.score_rank_chart .triangle li:hover {
  transform: scale(1.1);
}

.score_rank_chart .triangle li span {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  padding: 4px 16px;
}

.score_rank_chart .triangle li:nth-of-type(7)~li {
  color: #0000006b;
  font-weight: 700;
}
</style>