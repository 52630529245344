<template>
  <div>
    <div
      class="score_tab"
      style="padding: 0 40px; display: flex; justify-content: space-between"
    >
      <h4>查看排名</h4>
      <div class="rad_box">
        <label style="font-size: 14px; margin-right: 10px"
          >切换查看方式：</label
        >
        <el-radio-group v-model="curActive">
          <el-radio :label="1">图表</el-radio>
          <el-radio :label="2">列表</el-radio>
        </el-radio-group>
      </div>
    </div>

    <div class="new_score">
      <div class="new_score_container">
        <div class="user_info">
          <div class="photo">
            <img v-if="userInfo.avatar" :src="userInfo.avatar" alt="" />
            <i v-else class="el-icon-picture-outline"></i>
          </div>
          <div class="info">
            <div class="name">
               {{ userInfo.member_realname }}
              <span>{{ userInfo.username }}</span>
            </div>
            <div class="info_detail">
              <div class="item">
                <p>当前班级：{{ userInfo.class_name }}</p>
                <p>班级人数：{{ userInfo.class_count }}人</p>
              </div>
              <div class="item">
                <p>账号注册时间：{{ userInfo.register_time }}</p>
                <p>
                  排名统计时间：{{
                    userInfo.ranking_time ? userInfo.ranking_time : "暂无"
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <template v-if="curActive == 1">
          <ScoreRankChart
            :userinfo="userInfo"
            @show_more="getMore"
          ></ScoreRankChart>
        </template>

        <template v-else>
          <div class="tab">
            <el-radio-group
              v-model="queryForm.order_type"
              size="small"
              @change="handleClick"
            >
              <el-radio label="1">总分排名</el-radio>

              <el-radio label="2">流程分排名</el-radio>

              <el-radio label="3">运营分排名</el-radio>

              <el-radio label="4">利润分排名</el-radio>

              <el-radio label="5">教师评分排名</el-radio>
            </el-radio-group>
          </div>

          <el-form label-width="auto" size="small" inline>
            <el-form-item label="用户名：">
              <el-input
                v-model="queryForm.username"
                placeholder=""
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item label="真实姓名：">
              <el-input
                v-model="queryForm.member_truename"
                placeholder=""
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item label="">
              <el-button type="primary" @click="handleClick">查询</el-button>
            </el-form-item>
          </el-form>
          <div style="padding: 20px; background: #fff; border-radius: 5px">
            <div class="item">
              <el-table
                :data="scoreList"
                v-loading="loading"
                border
                :header-cell-style="{ background: '#f2f2f2' }"
              >
                <el-table-column label="排名" prop="bank"></el-table-column>

                <el-table-column
                  label="用户名"
                  prop="member_name"
                ></el-table-column>

                <el-table-column
                  label="真实姓名"
                  prop="member_realname"
                ></el-table-column>

                <el-table-column
                  label="学校"
                  prop="school_name"
                ></el-table-column>

                <el-table-column
                  label="班级"
                  prop="class_name"
                ></el-table-column>

                <el-table-column
                  label="总分"
                  prop="total_score"
                ></el-table-column>

                <el-table-column
                  label="流程分"
                  prop="flow_score"
                ></el-table-column>

                <el-table-column
                  label="运营分"
                  prop="operation_score"
                ></el-table-column>

                <el-table-column
                  label="利润分"
                  prop="profit_score"
                ></el-table-column>

                <el-table-column
                  label="教师评分"
                  prop="teacher_score"
                ></el-table-column>

                <el-table-column
                  prop="valid_orders_count"
                  label="有效订单数"
                  width="100"
                ></el-table-column>

                <el-table-column
                  prop="return_orders_count"
                  label="退货/退款订单数"
                  width="130"
                ></el-table-column>

 

                <el-table-column
                  prop="orders_total"
                  label="总订单数"
                ></el-table-column>

                <el-table-column
                  prop="profit_fee"
                  label="利润"
                ></el-table-column>

                <el-table-column
                  prop="sales_fee"
                  label="销售额"
                ></el-table-column>

                <el-table-column
                  prop="purchase_fee"
                  label="采购成本"
                ></el-table-column>

                <el-table-column
                  prop="order_ship_fee"
                  label="物流成本"
                ></el-table-column>

                <!-- <el-table-column prop="amazon_ship_fee" label="<?php echo $lang['FBA'];?>" width="130" ></el-table-column> -->

                <el-table-column
                  prop="adversting_fee"
                  label="推广费"
                ></el-table-column>

                <el-table-column
                  prop="platform_commission_fee"
                  label="平台佣金"
                ></el-table-column>
              </el-table>
            </div>

            <el-pagination
              style="margin: 10px 0 0"
              class="mt20"
              :total="total"
              :current-page="queryForm.page"
              :page-size="queryForm.page_size"
              :page-sizes="[10, 20, 50, 100]"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              layout="total, sizes, prev, pager, next, jumper"
            ></el-pagination>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { getUserInfo, getUserBank } from "@/api/shop.js";
import ScoreRankChart from "./scoreRankChart.vue";
export default {
  props: {
    show_content: {
      type: Number,
      default: 1, // 1图表2列表
    },
  },
  data() {
    return {
      curActive: 1,
      userInfo: {},
      loading: false,
      queryForm: {
        order_type: "1",
        username: "",
        member_truename: "",
        page: 1,
        page_size: 10,
      },
      total: 0,
      scoreList: [],
    };
  },
  components: {
    ScoreRankChart,
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    changView() {
      if (this.curActive == 2) {
        this.getRankList();
      }
    },
    // 图表点击查看更多
    getMore(val) {
      this.$emit("set_avtive", 2);
      this.curActive = 2;
      this.queryForm = {
        order_type: val,
        username: "",
        member_truename: "",
        page: 1,
        page_size: 10,
      };
      this.getRankList();
    },
    handleClick() {
      this.queryForm.page = 1;
      this.getRankList();
    },
    getUserInfo() {
      getUserInfo().then((res) => {
        if (res.code == 1) {
          this.userInfo = res.data;
        }
      });
    },
    getRankList() {
      this.loading = true;
      getUserBank(this.queryForm).then((res) => {
        this.loading = false;
        if (res.code == 1) {
          this.scoreList = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    handleSizeChange(val) {
      this.queryForm.page_size = val;
      this.getRankList();
    },
    handleCurrentChange(val) {
      this.queryForm.page = val;
      this.getRankList();
    },
  },
  watch: {
    curActive(val) {
      this.curActive = val;
      if (this.curActive == 2) {
        this.getRankList();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.new_score {
  width: 100%;
  /* background: #f0f1f5; */
  padding: 20px 0;
  box-sizing: border-box;
}
.new_score.is_table {
  background-color: #fff;
}
.new_score_container {
  width: 100%;
  margin: 0 auto;
}
.ai_chart {
  padding: 0 !important;
}
.score_header {
  display: flex;
  justify-content: space-between;
  line-height: 40px;
}
.rad_box label {
  margin-right: 8px;
}
.rad_box input[type="radio"] {
  position: absolute;
}
.new_score .user_info {
  width: 100%;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  box-sizing: border-box;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
}
.new_score .user_info .photo {
  width: 54px;
  height: 54px;
  background-color: #f3f3f3;
  border-radius: 50%;
  overflow: hidden;
  text-align: center;
  line-height: 56px;
  font-size: 24px;
  color: #989898;
  margin-right: 20px;
}
.new_score .user_info .photo img {
  width: 100%;
  height: 100%;
}
.new_score .user_info .info {
  display: flex;
  flex-direction: column;
}
.new_score .user_info .name {
  font-size: 16px;
  font-weight: 700;
  color: #666;
  margin-bottom: 8px;
}
.new_score .user_info .name span {
  display: inline-block;
  color: #fff;
  background-color: #f6b836;
  padding: 0px 6px;
  font-size: 12px;
  border-radius: 3px;
  margin-left: 10px;
  margin-top: -2px;
}
.new_score .user_info .info_detail {
  display: flex;
  line-height: 24px;
  font-size: 12px;
  color: #989898;
}
.new_score .user_info .info_detail .item {
  margin-right: 50px;
}
.new_score table {
  margin-bottom: 0 !important;
}
.new_score .el-table thead {
  line-height: 50px;
}
.new_score .el-table .cell {
  text-align: center;
}
.new_score .el-table td,
.el-table th {
  padding: 12px 0;
}
.new_score .el-table .cell {
  line-height: 24px;
}
.new_score .align_left .cell {
  text-align: left !important;
}
.new_score .tab {
  margin: 20px 0;
}
.new_score .score {
  margin-bottom: 20px;
  font-size: 14px;
}
</style>